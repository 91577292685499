// export const API_URL = 'http://192.168.10.223:8000/api';

// export const API_URL = 'https://api-beta.raideshsamaj.com/api';

export const API_URL = 'https://api.raideshsamaj.com/api';

export const HOME_URL = '/Members';

export const LOGIN_URL = '/login';

export const AUTH_TOKEN_NAME = 'raidesh_token';

export const USER_OBJECT_NAME = 'current_user';

export const AUTH_ENABLED = false;

export const API_MOCKING_ENABLED = false;
